html, body {
    background-repeat: repeat;
    font-family: 'Poppins', sans-serif;
    overflow-wrap: break-word;
}

.navbar-brand img
{
    max-height: 60px;
}

.navbar-dark {
    background-color: #212529;
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255,255,255,.85);
}

#home-leading-img {
    background: #fff;
    background-image: url("/src/assets/img/app-banner-both-device-size.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0;
    height: 100%;
    color: #f7f7f7;
    min-height: 60vh;
    text-align: center
}

#introText {
    font-size: 2.95rem;
    margin-top: 5rem;
}

#home-about {
    background-color: #99e1f0;
    border-radius: 15px;
    margin-top: -4rem;
    padding-top: 4rem;
    padding-bottom: 8rem;
}

.about-card {
    text-align: left;
}

.about-card:hover {
    border-left: solid #ED2776 0.3rem;
}

#home-benefits {
    background-color: #f7f7f7;
    margin-top: -4rem;
    padding-top: 4rem;
}

#home-breakdown {
    background-color: #99e1f0;
    margin-top: -4rem;
    padding-top: 4rem;
}

.benefit-card {
    background-image: url("/src/assets/img/app-banner-both-device-size.png");
    background-color: rgb(244, 113, 170);
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    padding: 2rem;
    margin-bottom: 2rem;
}

.benefit-card h2 {
    margin-bottom: 2.2rem;
    font-weight: 700;
    font-size: 2.3rem;
}

.benefit-card h4 {
    margin-bottom: 1.5rem;
    font-weight: 500;
    font-size: 1.35rem;
}

#portalHeader {
    background-image: url("/src/assets/img/app-banner-both-device-size.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 30vh;
    color: #fff;
    padding: 2rem;
    text-align: center;
    margin-bottom: 2.5rem;
}

.offer-card-featured-header {
    background-color: #06693A;
    color: #fff;
    padding: 0.35rem;
    text-align: center;
    font-weight: 600;
    font-size: 1.5rem;
}

#contact-hero {
    background-color: #0f0f0f;
}

#contact-info-panel {
    font-weight: 100;
    text-align: center;
}

#contact-info-panel h3 {
    line-height: 2rem;
}

#testimonial-section {
    background-color: #f471aa;
    padding-bottom: 4rem;
    padding-top: 4rem;
}

.testimonial-headshot {
    max-height: 150px;
    margin-bottom: 1.5rem;
}


#testimonial-section .active span {
    background-color: #ED2776;
}

#testimonial-section .carousel-control-prev, .carousel-control-next {
    display: none !important;
}

.review-card {
    background-color: #2b2b2b8c;
    color: #fff !important;
    padding: 3rem 4rem;
    border-radius: 8px;
}

footer
{
    border-top: solid #830000 15px;
    text-align: center;
}

footer .footer-logo
{
    max-height: 100px;
    margin-bottom: 1.5rem;
}

footer a
{
    color: #fff;
    text-decoration: none;
}

footer a:hover
{
    color: #fff;
    text-decoration: underline;
}

.footer-link-ul {
    list-style: none;
    padding-left: 0;
}

.footer-link {
    display: inline;
    text-decoration: none;
    margin-right: 20px;
}

.category-index-card {
    margin-bottom: 1rem !important;
}

.category-index-card a:hover {
    text-decoration: none;
}

.category-index-title {
    background-color: #c20037;
    color: #fff !important;
    text-align: center !important;
    text-transform: uppercase !important;
    font-weight: 900 !important;
    font-size: 16px !important;
    font-style: italic !important;
    padding: 15px 20px 15px 20px !important;
}

.home-header {
    position: relative;
    background-color: black;
    height: 95vh;
    min-height: 25rem;
    width: 100%;
    overflow: hidden;
    margin-bottom: 50px;
}

    .home-header video {
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: 0;
        -ms-transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
    }

    .home-header .container {
        position: relative;
        z-index: 2;
    }

    .home-header .home-overlay {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: black;
        opacity: 0.50;
        z-index: 1;
    }

    .home-header .header-logo {
        max-width: 300px;
    }

    .home-header .header-title {
        font-weight: 600;
        font-size: 35px;
        line-height: 40px;
    }

@media(min-width:576px) {
}

@media(min-width:768px) {
    .footer-link {
        float: left;
    }

    .footer-link-ul {
        margin-top: 20px;
    }
}

@media(min-width:992px) {

    .navbar-brand img {
        max-height: 90px;
    }

    #home-leading-img {
        text-align: left;
        padding-top: 7rem;
        min-height: 60vh;
    }

    #portalHeader {
        background-image: url("/src/assets/img/app-banner-both-device-size.png");
        min-height: 40vh;
    }

    footer
    {
        text-align: left;
    }

    .footer-link-ul {
        margin-top: 30px;
    }

    #contact-info-panel {
        padding-top: 4rem !important;
        padding-left: 4rem;
        font-weight: 100;
        text-align: left;
    }

    #contact-info-panel h3 {
        line-height: 2.75rem;
    }

    footer .footer-logo {
        margin-top: 25px;
        margin-bottom: 0;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    .btn-larger {
        font-size: 1.55rem;
    }

    .home-header {
        height: 75vh;
    }

        .home-header .header-title {
            font-size: 55px;
            line-height: 60px;
        }

    .category-index-title {
        font-size: 20px;
    }

}

@media(min-width:1200px) {
    .btn-larger {
        font-size: 1.75rem;
    }
}

@media(min-width:1600px) {
}




.color-primary {
    color: #C20037;
}

.custom-bg-secondary-darker {
    background-color: #00321b;
}


.color-white {
    color: #fff;
}

.color-blue {
    color: #99e1f0;
}

.background-color-pink {
    background-color: #ED2776;
}

.btn-larger {
    font-size: 1.75rem;
}

.btn-custom-primary {
    color: #FFFFFF !important;
    background-color: #C20037 !important;
    border-color: #C20037 !important;
}

    .btn-custom-primary:hover,
    .btn-custom-primary:focus,
    .btn-custom-primary:active,
    .btn-custom-primary.active,
    .open .dropdown-toggle.btn-custom-primary {
        color: #FFFFFF;
        background-color: #167800;
        border-color: #C20037;
    }

    .btn-custom-primary:active,
    .btn-custom-primary.active,
    .open .dropdown-toggle.btn-custom-primary {
        background-image: none;
    }

    .btn-custom-primary.disabled,
    .btn-custom-primary[disabled],
    fieldset[disabled] .btn-custom-primary,
    .btn-custom-primary.disabled:hover,
    .btn-custom-primary[disabled]:hover,
    fieldset[disabled] .btn-custom-primary:hover,
    .btn-custom-primary.disabled:focus,
    .btn-custom-primary[disabled]:focus,
    fieldset[disabled] .btn-custom-primary:focus,
    .btn-custom-primary.disabled:active,
    .btn-custom-primary[disabled]:active,
    fieldset[disabled] .btn-custom-primary:active,
    .btn-custom-primary.disabled.active,
    .btn-custom-primary[disabled].active,
    fieldset[disabled] .btn-custom-primary.active {
        background-color: #C20037;
        border-color:;
    }

    .btn-custom-primary .badge {
        color: #C20037;
        background-color: #FFFFFF;
    }

.btn-custom-secondary {
    color: #ffffff !important;
    background-color: #06693A !important;
    border-color: #06693A !important;
}

    .btn-custom-secondary:hover,
    .btn-custom-secondary:focus,
    .btn-custom-secondary:active,
    .btn-custom-secondary.active,
    .open .dropdown-toggle.btn-custom-secondary {
        color: #ffffff;
        background-color: #259E65;
        border-color: #06693A;
    }

    .btn-custom-secondary:active,
    .btn-custom-secondary.active,
    .open .dropdown-toggle.btn-custom-secondary {
        background-image: none;
    }

    .btn-custom-secondary.disabled,
    .btn-custom-secondary[disabled],
    fieldset[disabled] .btn-custom-secondary,
    .btn-custom-secondary.disabled:hover,
    .btn-custom-secondary[disabled]:hover,
    fieldset[disabled] .btn-custom-secondary:hover,
    .btn-custom-secondary.disabled:focus,
    .btn-custom-secondary[disabled]:focus,
    fieldset[disabled] .btn-custom-secondary:focus,
    .btn-custom-secondary.disabled:active,
    .btn-custom-secondary[disabled]:active,
    fieldset[disabled] .btn-custom-secondary:active,
    .btn-custom-secondary.disabled.active,
    .btn-custom-secondary[disabled].active,
    fieldset[disabled] .btn-custom-secondary.active {
        background-color: #06693A;
        border-color: #06693A;
    }

    .btn-custom-secondary .badge {
        color: #06693A;
        background-color: #ffffff;
    }

/* Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');